<template>
  <div class="row">
    <div
      class="col-md-3"
      style="margin-top: 12px"
    >
    <!--  -->
    </div>
    <div
      class="col-md-6"
      style="margin-top: 12px"
    >
      <form
        id="payment-form"
        class="border"
      >
        <div id="link-authentication-element">
          <!--Stripe.js injects the Link Authentication Element-->
        </div>
        <div id="payment-el">
          <!--Stripe.js injects the Payment Element-->
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          class="mt-2"
          variant="primary"
          :disabled="paymentProcessing"
          @click="processPayment"
        >
          {{ paymentProcessing ? "Processando..." : "Adicionar meio de pagamento." }}
        </b-button>

      </form>
    </div>
    <div
      class="col-md-3"
      style="margin-top: 12px"
    >
    <!--  -->
    </div>

    <b-table
      ref="refTable"
      class="position-relative mt-2 tableMinHeght"
      :items="paymentMethods"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="Nenhum resultado encontrado"
      no-sort-reset
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-warning my-2">
          <b-spinner
            class="align-middle"
          />
          <strong> Aguardando dados...</strong>
        </div>
      </template>
      <template #cell(cardExpiration)="data">
        {{ data.item.card.exp_month }}/{{ data.item.card.exp_year }}
      </template>

      <template #cell(cardDefault)="data">
        <feather-icon
          v-if="data.item.id === defaultPaymentMethod.id"
          icon="CheckCircleIcon"
          size="20"
          class="text-success stroke-current"
        />
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          v-if="data.item.id !== defaultPaymentMethod.id"
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            @click="handleMakeDefault(data.item)"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Tornar Padrão</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="handleDelete(data.item)"
          >
            <feather-icon icon="TrashIcon" />
            <span
              class="align-middle ml-50"
            >Excluir</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from '@axios'
import { loadStripe } from '@stripe/stripe-js'
import {
  BButton, BTable, BDropdown, BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  name: 'StripePayment',
  components: {
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },

  directives: {
    Ripple,
  },

  props: {
    token: {
      type: String,
      default: '',
    },

    action: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      modal: false,
      stripe: {},
      client_secret: '',
      paymentElement: {},
      elements: {},
      paymentProcessing: false,
      optionsToast: { timeout: 6000, position: 'topCenter' },
      paymentMethods: [],
      defaultPaymentMethod: null,
      isBusy: true,
      tableColumns: [
        {
          key: 'card.brand',
          label: 'Bandeira',
          tdClass: 'cardBrandTdClass',
          sortable: false,
        },
        {
          key: 'card.last4',
          label: 'Final',
          tdClass: 'cardFinalTdClass',
          sortable: false,
        },
        {
          key: 'cardExpiration',
          label: 'Válido até',
          tdClass: 'finalTdClass',
          sortable: false,
        },
        {
          key: 'cardDefault',
          label: 'Padrão',
          tdClass: 'defaultTdClass',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'ações',
          tdClass: 'actionsTdClass',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    skin() {
      return useAppConfig().skin.value
    },
  },

  watch: {
    skin() {
      this.initiateStripeElements({
        theme: this.skin === 'light' ? 'stripe' : 'night',
      })
    },
  },

  async mounted() {
    this.initiateStripeElements({
      theme: this.skin === 'light' ? 'stripe' : 'night',
    })
    this.getPaymentMethods()
  },

  methods: {
    async initiateStripeElements(appearance) {
      this.stripe = await loadStripe(`${process.env.VUE_APP_STRIPE_KEY}`)
      const resp = await this.getClientSecret()

      const payload = {
        clientSecret: resp.clientSecret,
        appearance,
      }

      this.elements = this.stripe.elements(payload)

      const paymentElementOptions = {
        layout: 'tabs',
      }

      this.paymentElement = this.elements.create(
        'payment',
        paymentElementOptions,
      )

      this.paymentElement.mount('#payment-el')
    },

    handleError(error) {
      if (error.response.status === 412) {
        this.$toast.warning(
          error.response.data.message,
          'AVISO',
          this.optionsToast,
        )
      } else {
        this.$toast.error(
          error.response.data.message,
          'Erro',
          this.optionsToast,
        )
      }
    },

    async getClientSecret() {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/stripe/intent`)
        return { clientSecret: resp.data.intent.client_secret }
      } catch (e) {
        return e
        // return this.handleError(e)
      }
    },

    async getPaymentMethods() {
      try {
        this.isBusy = true
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/stripe/stripe/get-subscription-info`)
        this.paymentMethods = resp.data.paymentMethods
        this.defaultPaymentMethod = resp.data.defaultPaymentMethod
        this.isBusy = false
      } catch (e) {
        this.isBusy = false
      }
    },

    async processPayment() {
      this.paymentProcessing = true

      const { setupIntent, error } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      })

      if (error) {
        this.paymentProcessing = false
        this.$toast.warning(
          'Por favor revise o formulário',
          'Aviso',
          this.optionsToast,
        )

        if (error.type === 'card_error' || error.type === 'validation_error') {
          // alert(error.message);
        } else {
          // alert("An unexpected error occurred.");
        }

        return
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/${this.action}`, {
          paymentMethod: setupIntent.payment_method,
        })
        this.paymentProcessing = false
        this.getPaymentMethods()
      } catch (e) {
        this.handleError(e)
      }
    },

    async makePaymentMethodDefault(data) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/update-default-payment-method`, {
          paymentMethod: data.id,
        })
        this.getPaymentMethods()
      } catch (e) {
        this.handleError(e)
      }
    },

    handleMakeDefault(data) {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja tornar o registro padrão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.makePaymentMethodDefault(data)
          this.$root.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'O registro foi alterado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    async deletePaymentMethod(data) {
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/stripe/delete-payment-method/${data.id}`)
        this.getPaymentMethods()
      } catch (e) {
        this.handleError(e)
      }
    },

    handleDelete(data) {
      this.$root.$swal({
        title: 'Tem certeza?',
        text: 'Deseja excluir o registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.deletePaymentMethod(data)
          this.$root.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'O registro foi excluído.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  min-height: 309px;
  align-self: center;
  padding: 40px;
}

.border {
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-el {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
.form-group label {
  font-weight: normal;
}

.form-group.has-error label {
  color: #df1b41;
  font-weight: normal;
}
</style>
