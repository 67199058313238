<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan2"
  >
    <b-modal
      id="handle-modal"
      v-model="showContactModal"
      centered
      size="lg"
      title="Mensagem"
      ok-title="Enviar Mensagem"
      :ok-disabled="!contactMessage"
      @ok="sendMessage"
      @hidden="contactMessage = ''"
    >
      <h3>Entre em contato</h3>
      <b-form-textarea
        id="message"
        v-model="contactMessage"
        rows="8"
        trim
      />
    </b-modal>

    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-2">
        Olá
      </h1>
      <p class="mb-2 mt-1 pb-75">
        {{ messageOne }}
        <br>Se você está tendo alguma dificuldade ou se tem dúvidas utilize o formulário abaixo para entrar em contato conosco. Teremos prazer em lhe ajudar.
      </p>
    </div>
    <!--/ title text and switch button -->

    <subscribe
      v-if="code === 'NOT_FOUND_SUBSCRIPTION'"
      action="subscribe"
    />

    <add-payment
      v-if="code === 'UNPAID'"
      action="add-payment-method"
    />

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Ainda tem dúvidas?
              </h3>
              <h5>
                Clique no botão a baixo e solicite uma demonstração sem compromisso com nossa equipe de suporte.</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="showContactModal = true"
              >
                Solicitar Contato
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div class="pricing-faq">
      <h3 class="text-center">
        Perguntas Frequentes
      </h3>
      <p class="text-center">
        Deixe-nos ajudar a responder às perguntas mais comuns.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >
            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BImg, BButton, BFormTextarea, BModal,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import Subscribe from '@/stripe/Subscribe.vue'
import AddPayment from '@/stripe/Payment.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BImg,
    BFormTextarea,
    BModal,
    AppCollapseItem,
    AppCollapse,
    Subscribe,
    AddPayment,
  },
  directives: {
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      messageOne: 'Parece que seu período de testes acabou. Utilize o formulário abaixo para reativar sua conta e continuar utilizando o Dental SIGO.',
      showContactModal: false,
      contactMessage: '',
      showLoading: false,
      pricing: {
        qandA: [
          {
            question: 'Minha assinatura é renovada automaticamente?',
            ans:
              'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
          },
          {
            question: 'Posso cancelar minha assinatura a qualquer momento?',
            ans:
              'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
          },
          {
            question: 'Posso alterar meu plano de assinatura a qualquer momento?',
            ans:
              'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
          },
        ],
      },
    }
  },

  mounted() {
    if (this.code === 'NOT_FOUND_SUBSCRIPTION') {
      this.messageOne = 'Parece que seu período de testes acabou. Utilize o formulário abaixo para reativar sua conta e continuar utilizando o Dental SIGO.'
    } else if (this.code === 'UNPAID') {
      this.messageOne = 'Parece que sua assinatura está em atraso. Utilize o formulário abaixo para alterar o meio de pagamento e continuar utilizando o Dental SIGO.'
    } else {
      router.push({ name: 'apps-patient-list' })
    }
  },

  methods: {
    showToast(icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') {
      this.$root.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton,
        },
      })
    },

    handleError(e) {
      if (e.response && (e.response.status === 412 || e.response.status === 422)) this.showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
      else if (e.response.status === 401) this.showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
      else this.showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
    },

    async sendMessage() {
      try {
        this.showLoading = true
        await axios.post(`${process.env.VUE_APP_API_URL}/stripe/send-feedback`, {
          message: this.contactMessage,
        })
        this.showToast('success', 'Sucesso', 'Já recebemos a sua mensagem. Retornaremos assim que for possível.')
        this.showLoading = false
      } catch (e) {
        this.handleError(e)
        this.showLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-pricing.scss';

  .pricing-free-trial {
    margin-top: 2.5rem;
    margin-bottom: 3.3rem;
  }

</style>
